'use client';

import { ApiRequestContext } from '@ocodelib/api-common';
import { useEffect, useRef } from 'react';

type Callback =
  | ((ctx: ApiRequestContext) => VoidFunction | undefined)
  | ((ctx: ApiRequestContext) => void);

export function useEffectApi(callback: Callback, deps: unknown[]) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    const ctx = ApiRequestContext.of();
    let close: any = undefined;
    setTimeout(() => {
      if (ctx.canceled) return;
      close = callbackRef.current(ctx);
    }, 0);
    return () => {
      ctx.cancel();
      if (typeof close === 'function') {
        close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
