'use client';

import { UserMainApi } from '@ocode/domain';
import { config } from '../config/index';
import {
  apiResponseInterceptor,
  createApiHeader,
  errorResponseInterceptor,
} from './api-interceptors';

const API_DEBUG = false;
const api = new UserMainApi({
  apiBaseURL: config.apiBaseURL,
  createApiHeader,
  responseInterceptor: apiResponseInterceptor,
  errorResponseInterceptor,
  debug: API_DEBUG,
});

export const useApi = () => api;
