'use client';

import type { WebPropBasicConfig } from '@ocode/domain';
import { createContext, useCallback, useContext } from 'react';
import useSWR from 'swr';
import { defaultBasicConfig } from '../util/default-basic-config';
import { useApi } from './useApi';
import { lodashGet } from '@ocodelib/util/misc';

export const BasicConfigContext = createContext<WebPropBasicConfig | null>(null);

export const useBasicConfigWebProp = () => useContext(BasicConfigContext)!;

export const useBasicConfigValue = <T,>(keyPath: string): T => {
  const props = useContext(BasicConfigContext)!;
  return lodashGet(props, keyPath) as T;
};

const MINUTE = 60 * 1000;
const REFRESH_INTERVAL = 7 * MINUTE;

interface Props {
  children: React.ReactNode;
}

export function BasicConfigWebPropProvider(props: Props) {
  const { children } = props;
  const api = useApi();
  const fetcher = useCallback(async () => {
    const { webProp } = await api.webProp.findByPropKey({ propKey: 'basicConfig' });
    const json = webProp.value;
    return { ...defaultBasicConfig, ...JSON.parse(json) };
  }, [api]);

  const { data, error, isLoading } = useSWR('BasicConfigWebProp', fetcher, {
    refreshInterval: REFRESH_INTERVAL,
    dedupingInterval: REFRESH_INTERVAL,
  });

  // console.log('BasicConfigWebPropProvider', { data, error, isLoading })
  return (
    <BasicConfigContext.Provider value={data ?? defaultBasicConfig}>
      {children}
    </BasicConfigContext.Provider>
  );
}
