import { DatiResources } from '../dati/DatiResources';

// 프로필 기본 이미지
const urlOfProfileImg = (num: number) =>
  DatiResources.encodeUrlAbs('sys.assets', `user_profile_img/user_profile_default_${num}.png`);

export const DEFAULT_PROFILE_IMAGES = [
  urlOfProfileImg(1),
  urlOfProfileImg(2),
  urlOfProfileImg(3),
  urlOfProfileImg(4),
  urlOfProfileImg(5),
  urlOfProfileImg(6),
  urlOfProfileImg(7),
  urlOfProfileImg(8),
];

export const DEFAULT_PROFILE_IMAGE_URL = DEFAULT_PROFILE_IMAGES[0];
