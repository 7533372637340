// import { SingleValueRxEmitter } from '@ocodelib/ex-util';

// export const LectureViewDialogOpenEvent = new SingleValueRxEmitter<string | null>(null);

/**
 * LectureViewDialog 오픈 요청
 * param은 lectureKey
 * lectureKey 포맷: {inkKind}.{bookSlug or bookId}
 * ex) lecture.py-basic
 *
 * LectureViewDialogOpenEvent는 CustomEvent를 사용한다.
 * 외부 라이브러리에서도 CustomEvent를 보냄으로써 Lecture 다이얼로그를 띄울 수 있다.
 * 즉, 외부 라이브러리에서 종속성 없이 Lecture 다이얼로그를 띄울 수 있다.
 */
export const LectureViewDialogOpenEvent = {
  emit: (lectureKey: string) => {
    document.dispatchEvent(
      new CustomEvent('lecture-dialog-open', {
        detail: {
          lectureKey,
        },
      }),
    );
  },
};
