'use client';

import { useEffect } from 'react';
import { interval } from 'rxjs';
import { LoginProfileRefreshRequestEvent } from '../auth/authentication';
import { useLoginUser } from '../store/useAuthStore';

const MINUTE = 60 * 1000;
const CHECK_INTERVAL = 30_000;
const REFRESH_INTERVAL = 2 * MINUTE;

export function useProfileMePeriodic() {
  const loginUser = useLoginUser();
  const profile = loginUser.profile;

  useEffect(() => {
    if (!profile) return;
    const s1 = interval(CHECK_INTERVAL)
      .pipe()
      .subscribe(() => {
        // console.log('profile.__loadedTimestamp=',profile.__loadedTimestamp)
        const diff = Date.now() - (profile.__loadedTimestamp ?? 0);
        if (diff >= REFRESH_INTERVAL) {
          console.log('useProfileMePeriodic() LoginProfileRefreshRequestEvent.emit()');
          LoginProfileRefreshRequestEvent.emit(Date.now());
        }
      });
    return () => {
      s1.unsubscribe();
    };
  }, [profile]);
}
