'use client';

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import type { TextFieldProps } from '@mui/material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { forwardRef, useState } from 'react';

type Props = Omit<TextFieldProps, 'type' | 'InputProps'>;

export const PasswordTextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [visible, setVisible] = useState(false);
  const { ...rest } = props;

  return (
    <TextField
      ref={ref}
      type={visible ? 'text' : 'password'}
      {...rest}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={() => {
                setVisible((p) => !p);
              }}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              size="large"
            >
              {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
});

PasswordTextField.displayName = 'PasswordTextField';
