'use client';

import { useMediaQuery, useTheme } from '@mui/material';
import { CustomDialog } from '../../components/CustomDialog';
import { LoginForm } from './login/LoginForm';

export interface LoginDialogProps {
  open: boolean;
  onClose: () => void;
}

export function LoginDialog(props: LoginDialogProps) {
  const { open, onClose } = props;
  const theme = useTheme();
  const xsOrDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDialogClose = () => {
    onClose();
  };

  // 로그인 성공 콜백
  const handleLoginSuccess = () => {
    onClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={handleDialogClose}
      maxWidth="sm"
      className="LoginDialog-root"
      fullWidth={xsOrDown}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '10px',
          px: 1,
        },
      }}
    >
      <LoginForm onClose={handleDialogClose} onLoginSuccess={handleLoginSuccess} />
    </CustomDialog>
  );
}
