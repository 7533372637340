// 로그인 페이지
export const LOGIN_PAGE = '/login';

// 로그아웃 페이지
export const LOGOUT_PAGE = '/logout';

// 개발자 테스트용, API 디버깅 - 운영서버에서는 false
export const DEBUG_API = false;

// User 비밀번호 길이
export const USER_PASSWD_MIN_LEN = 4;
export const USER_PASSWD_MAX_LEN = 40;

import { koKR } from '@mui/x-date-pickers/locales';

export const DEFAULT_DATE_PICKER_LOCALE = koKR;
