import type { PaletteOptions } from '@mui/material';

export const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    contrastText: '#fff',
    light: '#4dc4ff',
    main: '#00ACFF',
    dark: '#0c89db',
  },
  secondary: {
    contrastText: '#FFF',
    light: '#f15e8f',
    main: '#ea0e5e',
    dark: '#c30757',
  },
};
