'use client';

import { useCallback, useEffect, useState } from 'react';
import { LoginDialogOpenEvent } from './LoginDialogOpenEvent';
import { LoginDialog, type LoginDialogProps } from '../../dialogs/LoginDialog/index';

type DialogParams = {
  id: 'LoginDialog';
  props: LoginDialogProps;
};

export function LoginDialogOpenEventHandler() {
  const [dialogParams, setDialogParams] = useState<DialogParams | undefined>(undefined);

  const openLoginDialog = useCallback(() => {
    setDialogParams({
      id: 'LoginDialog',
      props: {
        open: true,
        onClose: () => {
          setDialogParams(undefined);
        },
      },
    });
  }, []);

  useEffect(() => {
    // 0이면 초기값이므로 무시한다
    LoginDialogOpenEvent.on((tick) => {
      if (!tick) return;
      openLoginDialog();
    });
  }, [openLoginDialog]);

  if (!dialogParams) return null;

  return <>{dialogParams.id === 'LoginDialog' && <LoginDialog {...dialogParams.props} />}</>;
}
