import { EventEmitter } from '@jjfive/eventemitter3';
import { PathHolder } from '@ocode/domain';

interface CustomRouterEventMap {
  push: (link: string) => void;
  back: () => void;
}

export const customRouter = new EventEmitter<CustomRouterEventMap>();

/**
 * ex) routerPush('a', 'b', 'c') => '/a/b/c'
 * ex) routerPush('/en/py/list') => '/en/py/list'
 * ex) routerPush('en/py/list') => '/en/py/list'
 * ex) routerPush('en', 'py','list') => '/en/py/list'
 * ex) routerPush('en', 'py', undefined,'list') => '/en/py/list'
 * ex) routerPush('en', 'py', null, 'list') => '/en/py/list'
 * ex) routerPush('en', 'py/list') => '/en/py/list'
 * ex) routerPush('en', '/py/list') => '/en/py/list'
 * ex) routerPush('en', '//py/list') => '/en/py/list'
 * ex) routerPush('en','/','py','list') => '/en/py/list'
 * ex) routerPush('en', '//py/list/') => '/en/py/list'
 */
export function routerPush(link1: string, ...extraLinks: (string | number | undefined | null)[]) {
  let link = link1;
  if (extraLinks.length > 0) {
    link = '/' + PathHolder.from(link1, ...extraLinks.map((it) => '' + it)).path;
  }
  customRouter.emit('push', link);
}

export function routerBack() {
  customRouter.emit('back');
}
