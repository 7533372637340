import { isLoginError } from '@ocode/domain';
import type { AppError, ResponseInterceptor } from '@ocodelib/api-common';
import type { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  BrowserIdStorage,
  LoginProfileRefreshRequestEvent,
  LoginTokenStorage,
} from '../auth/authentication';
import { config } from '../config/index';
import { logger } from '../logger';

const DEBUG = false;

const HTTP_TOKEN_HEADER = config.httpHeaderKeys.token;
const HTTP_TOKEN_CLEAR_HEADER = config.httpHeaderKeys.tokenClear;

/**
 * Axios Response 인터셉터
 * HTTP 헤더에서 인증 토큰을 추출하여 로컬 저장소에 보관한다
 * 또는 인증 토큰을 제거한다
 */
export const apiResponseInterceptor: ResponseInterceptor = (
  response: AxiosResponse,
): AxiosResponse | Promise<AxiosResponse> => {
  if (!response.headers) return response;

  const token = response.headers[HTTP_TOKEN_HEADER];
  if (token && token.length > 0) {
    if (DEBUG) logger.log(`SAVE AUTH HEADER: ${token}`);
    const oldToken = LoginTokenStorage.value;
    if (oldToken !== token) {
      if (DEBUG) {
        console.log('신규 인증 토큰:', token, ', old=', oldToken);
      }
      LoginTokenStorage.value = token;
      LoginProfileRefreshRequestEvent.emit(Date.now());
    } else {
      if (DEBUG) {
        console.log('동일한 인증 토큰 무시:', token);
      }
    }
    return response;
  }

  const clearAuth = response.headers[HTTP_TOKEN_CLEAR_HEADER];
  if (clearAuth && clearAuth.length > 0) {
    const oldToken = LoginTokenStorage.value;
    if (oldToken) {
      LoginTokenStorage.clear();
      LoginProfileRefreshRequestEvent.emit(Date.now());
    }
    if (DEBUG) {
      logger.log('REMOVE AUTH HEADER');
    }
  }
  return response;
};

export const errorResponseInterceptor = (error: AppError): void => {
  if (DEBUG) {
    logger.log('errorResponseInterceptor()', { error });
  }
  if (isLoginError(error)) {
    if (DEBUG) {
      logger.log('errorResponseInterceptor loginError', error);
    }
    LoginTokenStorage.clear();
    LoginProfileRefreshRequestEvent.emit(Date.now());
  } else {
    if (DEBUG) {
      logger.log('errorResponseInterceptor notLoginError', error);
    }
  }
};

/**
 * 인증 토큰 HTTP 헤더를 생성한다
 */
export const createApiHeader = (): Record<string, string> => {
  const headers = {} as any;
  const token = LoginTokenStorage.value;
  if (token && token.length > 0) {
    headers['Authorization'] = 'Bearer ' + token;
  }
  let browserId = BrowserIdStorage.value;
  // console.log("XXX curr 브라우저ID", browserId);
  if (!browserId) {
    browserId = uuidv4();
    BrowserIdStorage.value = browserId;
  }
  headers['x-custom-device'] = browserId;

  // console.log("createApiHeader", headers);
  return headers;
};
