import type { UserAccountWithTimestamp } from '@ocode/domain';

export class LoginUser {
  readonly profile: UserAccountWithTimestamp | undefined;
  constructor(profile: UserAccountWithTimestamp | undefined) {
    this.profile = profile;
  }

  get loginAccountId() {
    return this.profile?.accountId;
  }

  get loginId() {
    return this.profile?.loginId;
  }

  get isAdmin(): boolean {
    return this.profile?.loginId === 'master';
  }

  get isLoggedIn(): boolean {
    return !!this.profile;
  }

  get isLoggedOut(): boolean {
    return !this.profile;
  }
}
