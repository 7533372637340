import { config } from '../config/index';
import { PathHolder } from '@ocode/domain';

const datiBaseUrl = config.datiBaseURL;

export class DatiResources {
  constructor(public readonly datiId: string) {
    // empty
  }

  toUrl = (download: boolean, ...relPath: string[]) => {
    return DatiResources.encodeUrl_(this.datiId, PathHolder.from(relPath), download);
  };

  static encodeUrlAbs = (datiId: string, relPath: string, download = false): string => {
    return DatiResources.encodeUrlAbs_(datiId, PathHolder.from(relPath), download);
  };

  static encodeUrl = (datiId: string, relPath: string, download = false): string => {
    return DatiResources.encodeUrl_(datiId, PathHolder.from(relPath), download);
  };

  private static encodeUrlAbs_ = (
    datiId: string,
    pathHolder: PathHolder,
    download = false,
  ): string => {
    const suffix = download ? '?download=true' : '';
    const path = pathHolder.segments.map((it) => encodeURIComponent(it)).join('/');
    return `${datiBaseUrl}/d_/${datiId}/${path}${suffix}`;
  };

  private static encodeUrl_ = (
    datiId: string,
    pathHolder: PathHolder,
    download = false,
  ): string => {
    const suffix = download ? '?download=true' : '';
    const path = pathHolder.segments.map((it) => encodeURIComponent(it)).join('/');
    return `/d_/${datiId}/${path}${suffix}`;
  };
}

export function datiUrlBuilder<T extends string>(options: {
  datiId: string;
  currentFolderPath: string;
  urlRelative: boolean;
}) {
  const { datiId, currentFolderPath, urlRelative } = options;
  const func = urlRelative ? DatiResources.encodeUrl : DatiResources.encodeUrlAbs;
  return (name: T, download = false) => {
    return func(datiId, `${currentFolderPath}/${name}`, download);
  };
}
