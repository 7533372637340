'use client';

import type { CoppiaPub } from '@ocode/domain';
import { ReactNode, createContext, useCallback, useContext } from 'react';
import useSWR from 'swr';
import { useApi } from './useApi';

const CoppiaPubContext = createContext<CoppiaPub | null>(null);

export const useCoppiaPub = () => useContext(CoppiaPubContext);

const MINUTE = 60 * 1000;
const REFRESH_INTERVAL = 12 * (60 * MINUTE);

export function CoppiaPubProvider(props: Props) {
  const { children } = props;
  const api = useApi();
  const fetcher = useCallback(async () => {
    const coppia = await api.coppia.issue({});
    return coppia;
  }, [api]);

  const { data, error, isLoading } = useSWR('CoppiaPub', fetcher, {
    refreshInterval: REFRESH_INTERVAL,
    dedupingInterval: REFRESH_INTERVAL,
  });

  return (
    <CoppiaPubContext.Provider value={data ?? DEFAULT_COPPIA}>{children}</CoppiaPubContext.Provider>
  );
}

const DEFAULT_COPPIA: CoppiaPub = {
  cid: 0,
  pubKey: '',
  key: '',
};

interface Props {
  children: ReactNode;
}
