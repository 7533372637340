const HTTP_TOKEN_HEADER = 'x-custom-authorization';
const HTTP_TOKEN_CLEAR_HEADER = 'x-custom-authorization-clear';

const baseURL = process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000';
const apiBaseURL = process.env.NEXT_PUBLIC_API_BASE_URL || 'https://ocode.kr/ocode-server';
const routerBaseURL = process.env.NEXT_PUBLIC_ROUTER_BASE_URL || '/';
const datiBaseURL = apiBaseURL;
const isProduction = ['ocode.kr', 'adm.ocode.kr'].some((it) => apiBaseURL.indexOf(it) >= 0);
const DEBUG = false;

export const config = {
  isProduction,
  debug: DEBUG,
  storageKeys: {
    authToken: '_auth_token',
    profile: '_auth_profile',
    browserId: '_browser',
    aiToken: '_ai_token',
  },
  httpHeaderKeys: {
    token: HTTP_TOKEN_HEADER,
    tokenClear: HTTP_TOKEN_CLEAR_HEADER,
  },
  apiBaseURL,
  baseURL,
  datiBaseURL,
  routerBaseURL,
  googleOauth2ClientId: '588818548399-6m2mhq261ih1v8ldij1mo96euentdb0i.apps.googleusercontent.com',
};
