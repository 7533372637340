'use client';

import { useRouter } from 'next/navigation';
import { useIsomorphicLayoutEffect } from 'react-use';
import { customRouter } from '../router/index';

export function useRouterEvent() {
  const router = useRouter();

  useIsomorphicLayoutEffect(() => {
    const onRouterPush = (link: string) => {
      router.push(link);
    };

    const onRouterBack = () => {
      router.back();
    };

    customRouter.on('push', onRouterPush);
    customRouter.on('back', onRouterBack);
    return () => {
      customRouter.off('push', onRouterPush);
      customRouter.off('back', onRouterBack);
    };
  }, [router]);
}
