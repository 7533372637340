import type { Palette, ThemeOptions } from '@mui/material/styles';
import { notoSansKR } from '../font';

// const fontFamily = [
//   // "Noto Sans KR", //
//   notoSansKR.style.fontFamily,
//   "SCoreDream",
//   "나눔스퀘어라운드",
//   "맑은 고딕",
//   "sans-serif",
//   "Nanum Gothic Coding",
//   "y_spotlight",
// ]
//   .map((it) => `'${it}'`)
//   .join(",");

export const typography: ThemeOptions['typography'] = (palette: Palette) => ({
  // fontFamily,
  ...notoSansKR.style,
  // fontFamily: scoreDream.style.fontFamily,
  fontDisplay: 'swap',
  allVariants: {
    fontFamily: notoSansKR.style.fontFamily,
    fontWeight: 600,
  },

  h1: {
    fontWeight: 300,
    fontSize: '6rem',
    letterSpacing: '-5%',
    lineHeight: '130%',
  },
  h2: {
    fontWeight: 900,
    fontSize: '3.5rem',
    letterSpacing: '-2%',
    lineHeight: '150%',
  },
  h3: {
    fontWeight: 700,
    fontSize: '3rem',
    letterSpacing: '-3%',
    lineHeight: '140%',
  },
  h4: {
    fontWeight: 500,
    fontSize: '2.25rem',
    letterSpacing: '-5%',
    lineHeight: '150%',
  },
  h5: {
    fontWeight: 500,
    fontSize: '1.3rem',
    letterSpacing: '-3%',
    lineHeight: '150%',
  },
  h6: {
    fontWeight: 700,
    fontSize: '1.125rem',
    letterSpacing: '-2%',
    lineHeight: '150%',
  },
  subtitle1: {
    fontWeight: 700,
    fontSize: '1rem',
    letterSpacing: '-2%',
    lineHeight: '170%',
  },
  subtitle2: {
    fontWeight: 900,
    fontSize: '0.875rem',
    letterSpacing: 0,
    lineHeight: '170%',
  },
  body1: {
    fontWeight: 500,
    fontSize: '0.83rem',
    letterSpacing: '-3%',
    lineHeight: '170%',
  },
  body2: {
    fontWeight: 500,
    fontSize: '0.8rem',
    letterSpacing: '-1%',
    lineHeight: '170%',
  },
  button: {
    fontWeight: 700,
    color: palette.text.primary,
    fontSize: '0.85rem',
    letterSpacing: '2%',
    lineHeight: '170%',
  },
  caption: {
    fontWeight: 500,
    color: palette.text.disabled,
    fontSize: '0.75rem',
    letterSpacing: '-2%',
    lineHeight: '150%',
  },
  overline: {
    fontWeight: 900,
    fontSize: '0.625rem',
    letterSpacing: '10%',
    lineHeight: '200%',
  },
});
