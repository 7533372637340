'use client';

import { LectureViewDialogOpenEventHandler } from '@repo-ui/events/lecture-open';
import { LightboxOpenEventHandler } from '@repo-ui/events/lightbox-open';
import { useRouterEvent } from '@repo-ui/hooks/useRouterEvent';
import { useUnlockAudio } from '@repo-ui/hooks/useUnlockAudio';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function CommonEventHandlers() {
  useUnlockAudio();
  useRouterEvent();
  return (
    <>
      <LightboxOpenEventHandler />
      <LectureViewDialogOpenEventHandler />
      {/* 느려서 제거 */}
      {/* <MonacoThemePickerOpenEventHandler /> */}
      <ToastContainer
        position="top-center"
        hideProgressBar
        autoClose={2500}
        pauseOnHover
        draggable
      />
    </>
  );
}
