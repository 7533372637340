'use client';

import { type UserAccountWithTimestamp } from '@ocode/domain';
import { ApiRequestContext } from '@ocodelib/api-common';
import { useIsomorphicLayoutEffect } from 'ahooks';
import { useCallback, useEffect } from 'react';
import { useProfileMePeriodic } from '../hooks/useProfileMePeriodic';
import { logger } from '../logger';
import { useApi } from '../provider/useApi';
import { useAuthStore } from '../store/useAuthStore';
import {
  LoginProfileLoadedEvent,
  LoginProfileRefreshRequestEvent,
  LoginProfileStorage,
  LoginTokenStorage,
} from './authentication';

const DEBUG = true;

/**
 * LoginProfileLoader는 (로컬) 스토리지로부터 인증 토큰 및 프로필 정보를 읽어서
 * zustand 스토어에 저장하는 역할을 합니다.
 *
 * LoginProfileLoader 이외의 컴포넌트들은
 * zustand 스토어에 프로필 정보가 있으면 로그인 된 것으로 판단합니다.
 *
 * 재접속 또는 새로고침시 로그인 유지를 위해 인증 토큰을 로컬 스토리지에 보관합니다.
 * 로컬 스토리지의 인증 토큰을 읽어서 profile-me api를 호출하여 프로필 정보를 로드하면
 * 로그인 된 것으로 간주할 수 있습니다.
 *
 * 프로필 정보는 서버로부터 불러오면 되므로 메모리 상에서만 보관하면 되지만,
 * 로컬 스토리지에도 보관합니다. 그 이유는 새로고침할 때, 서버와 연동하기 전에
 * 프로필 정보를 표시하기 위해서입니다.
 *
 * (기타)
 * 로컬 스토리지에 저장하는 인증 및 프로필 데이터들은 모두 AES 암호화합니다.
 */
export function LoginProfileLoader() {
  const api = useApi();
  const setUserProfile = useAuthStore((s) => s.setUserProfile);
  const setAuthToken = useAuthStore((s) => s.setAuthToken);
  const logout = useAuthStore((s) => s.logout);

  // 로그인이 된 경우 주기적으로 프로필을 갱신한다
  useProfileMePeriodic();

  const doLoadProfile = useCallback(
    async (ctx: ApiRequestContext): Promise<UserAccountWithTimestamp | null> => {
      try {
        const { profile } = await api.userProfile.profileMe({ ctx });
        if (ctx.canceled) return null;
        return { ...profile, __loadedTimestamp: Date.now() };
      } catch (e) {
        console.log('LoginProfileLoader: profile load fail', e);
      }
      return null;
    },
    [api],
  );

  // 로그인 프로필 갱신 이벤트를 수신하여 프로필 정보를 로드합니다.
  // LoginProfileRefreshRequestEvent를 수신하여, 프로필 정보를 로드한 후,
  // LoginProfileLoadedEvent를 emit(userProfile) 합니다.
  useIsomorphicLayoutEffect(() => {
    let lastCtx: ApiRequestContext | null = null;
    const cancelCtx = () => {
      if (lastCtx) {
        lastCtx.cancel();
      }
    };

    // 로그인 프로필 갱신 이벤트 핸들러
    const dispose = LoginProfileRefreshRequestEvent.on(() => {
      if (DEBUG) {
        console.log(
          'LoginProfileLoader: LoginProfileRefreshRequestEvent.onChange()',
          LoginTokenStorage.exists(),
        );
      }
      if (!LoginTokenStorage.exists()) {
        cancelCtx();
        return;
      }

      // 프로필 정보 로드
      const ctx_ = ApiRequestContext.of();
      lastCtx = ctx_;
      doLoadProfile(ctx_)
        .then((value) => {
          if (DEBUG) {
            console.log('LoginProfileLoader: doLoadProfile()', value);
          }
          LoginProfileLoadedEvent.emit(value ?? undefined);
        })
        .finally(() => {
          ctx_.cancel();
        });
    });

    return () => {
      dispose();
      cancelCtx();
    };
  }, [doLoadProfile]);

  // 초기화: 로그인 정보를 storage로부터 로드하여 zustand 스토어에 저장
  useIsomorphicLayoutEffect(() => {
    if (DEBUG) {
      logger.log('초기화: 로그인 정보를 storage로부터 로드하여 zustand 스토어에 저장');
    }
    const authToken = LoginTokenStorage.value;
    const profile = LoginProfileStorage.value;
    if (authToken && profile) {
      if (DEBUG) {
        logger.log('LoginProfileLoader: 스토리지 정보로부터 로그인', profile);
      }
      setAuthToken(authToken);
      setUserProfile(profile);
    } else {
      if (DEBUG) {
        logger.log('LoginProfileLoader: 스토리지 정보 없음: 로그아웃 상태');
      }
      logout();
    }
    return () => {
      if (DEBUG) {
        logger.log('LoginProfileLoader: 로그인 정보 동기화 언마운트');
      }
    };
  }, [setAuthToken, setUserProfile, logout]);

  // 로그인 토큰이 삭제되면, 강제로 로그아웃
  useIsomorphicLayoutEffect(() => {
    // 로그인 토큰
    const dispose1 = LoginTokenStorage.on((value) => {
      if (DEBUG) {
        logger.log('LoginProfileLoader: LoginTokenStorage.on()', value);
      }
      setAuthToken(value);
      // 토큰을 제거한 경우 프로필도 제거
      if (!value) {
        LoginProfileLoadedEvent.emit(undefined);
      }
    });

    // // 로그인 프로필
    // const dispose2 = LoginProfileStorage.once((value) => {
    //   if (DEBUG) {
    //     logger.log("LoginProfileLoader: LoginProfileStorage.on()", value);
    //   }
    //   setUserProfile(value);
    // });

    return () => {
      dispose1();
      // dispose2();
    };
  }, [setAuthToken, setUserProfile]);

  // LoginProfileLoadedEvent 이벤트를 수신하여 zustand 스토어에 저장
  useEffect(() => {
    const dispose = LoginProfileLoadedEvent.on((value) => {
      setUserProfile(value);
      LoginProfileStorage.value = value;
    });
    return () => {
      dispose();
    };
  }, [setUserProfile]);

  return null;
}
