import { create } from 'zustand';
import type { UserAccountWithTimestamp } from '@ocode/domain';
import { LoginUser } from '../auth/LoginUser';

const DEBUG = false;

const ANONYMOUS = new LoginUser(undefined);

export interface AuthState {
  user: LoginUser;
  token?: string;
  setAuthToken: (token?: string) => void;
  setUserProfile: (profile?: UserAccountWithTimestamp) => void;
  logout: () => void;
}

export const useAuthStore = create<AuthState>()((set) => ({
  token: undefined,
  user: ANONYMOUS,
  setAuthToken: (token?: string) => {
    if (DEBUG) {
      console.log('useAuthStore.setAuthToken() token=', token);
    }
    set((prev) => ({
      token,
    }));
  },
  setUserProfile: (profile?: UserAccountWithTimestamp) => {
    console.log('useAuthStore.setUserProfile() profile=', profile);
    set((prev) => ({
      user: new LoginUser(profile),
    }));
  },
  logout: () => {
    if (DEBUG) {
      console.log('useAuthStore.logout()');
    }
    set((prev) => ({
      profile: new LoginUser(undefined),
      token: undefined,
    }));
  },
}));

export const useLoginUser = (): LoginUser => useAuthStore((state) => state.user);
