'use client';

import { koKR as coreKoKR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { koKR as dataGridKoKR } from '@mui/x-data-grid/locales';
import { koKR } from '@mui/x-date-pickers/locales';
import { palette } from './palette';
import { typography } from './typography';

const theme = createTheme(
  {
    palette,
    typography,
    components: {
      // MuiAlert: {
      //   styleOverrides: {
      //     root: ({ ownerState }) => ({
      //       ...(ownerState.severity === 'info' && {
      //         backgroundColor: '#60a5fa',
      //       }),
      //     }),
      //   },
      // },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.MuiButtonBase-root': {
              // fontFamily: notoSansKR.style.fontFamily,
              fontWeight: 500,
              textTransform: 'none',
            },
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        // lg: 1280,
        lg: 1180,
        xl: 1536,
      },
    },
  },
  koKR,
  dataGridKoKR,
  coreKoKR,
);

export default theme;
