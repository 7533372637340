'use client';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  LinearProgress,
  Tab,
  Tabs,
} from '@mui/material';
import { ink, UploadedFile } from '@ocode/domain';
import { ApiRequestContext } from '@ocodelib/api-common';
import { sxDialogHeight } from '@ocodelib/ui-common';
import * as stringUtils from '@ocodelib/util/string';
import { CustomDialogTitle } from '@repo-shared-base/components/CustomDialogTitle';
import { useSize } from 'ahooks';
import { Allotment, LayoutPriority } from 'allotment';
import 'allotment/dist/style.css';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { toast } from 'react-toastify';
import { CustomDialog } from '../../components/CustomDialog';
import { useEffectApi } from '../../hooks/useEffectApi';
import { useApi } from '../../provider/useApi';
import { handleError } from '../../util/handle-error';
import { LectureContents } from './components/LectureContents';
import { LeftTocList } from './components/LeftTocList';
import { ItemChild, ItemNode } from './types';

type BookIdentifier =
  | {
      bookId: number;
    }
  | { slug: string };

export interface LectureViewDialogProps {
  open: boolean;
  onClose: () => void;
  lectureKey: string;
  // kind: ink.InkKindKey;
  // bookIdentifier:
  //   | {
  //       bookId: number;
  //     }
  //   | { slug: string };
}

function splitLectureKeys(lectureKey: string):
  | undefined
  | {
      kind: ink.InkKindKey;
      bookIdentifier: BookIdentifier;
    } {
  const arr = lectureKey.split('.');
  if (arr.length !== 2) {
    return undefined;
  }

  const kind = arr[0] as ink.InkKindKey;
  if (stringUtils.isNumeric(arr[1])) {
    return {
      kind,
      bookIdentifier: { bookId: +arr[1] },
    };
  }

  return {
    kind,
    bookIdentifier: { slug: arr[1] },
  };
}

export function LectureViewDialog(props: LectureViewDialogProps) {
  const api = useApi();
  const { open, onClose } = props;
  const { kind, bookIdentifier } =
    useMemo(() => splitLectureKeys(props.lectureKey), [props.lectureKey]) ?? {};

  const [loading, setLoading] = useState(false);
  const [tabId, setTabId] = useState(0);
  const dialogContentRef = useRef<HTMLDivElement>();
  const rootSize = useSize(dialogContentRef);
  const [bookItemId, setBookItemId] = useState<number>(-1);
  const [bookDetails, setBookDetails] = useState<{
    book: ink.BookPubl;
    bookItems: ItemNode[];
    attachFiles?: UploadedFile[];
  }>();

  useEffect(() => {
    console.log('bookItemId changed', bookItemId);
  }, [bookItemId]);

  useEffect(() => {
    if (!kind || !bookIdentifier) {
      toast.warn(`lectureId 파라미터가 잘못 전달되었습니다.(${props.lectureKey})`);
    }
  }, [kind, bookIdentifier, props.lectureKey]);

  const bookItem = useMemo(() => {
    if (bookItemId < 0) return undefined;
    const bookItems = bookDetails?.bookItems ?? [];
    if (bookItems.length === 0) {
      return undefined;
    }
    for (let i = 0; i < bookItems.length; i++) {
      const item = bookItems[i];
      if (item.itemId === bookItemId) {
        if (item.itemType === 'doc') {
          return item as ItemChild;
        }
      }
      for (let k = 0; k < item.children.length; k++) {
        const child = item.children[k];
        if (child.itemId === bookItemId) {
          return child as ItemChild;
        }
      }
    }
    return undefined;
  }, [bookDetails?.bookItems, bookItemId]);

  // BOOK 상세 정보 가져오기
  const doLoadBookDetails = useCallback(
    async (
      ctx: ApiRequestContext,
      kind: ink.InkKindKey,
      bookIdentifier:
        | {
            bookId: number;
          }
        | { slug: string },
    ) => {
      try {
        setLoading(true);
        const {
          book,
          bookItems,
          attachFiles = [],
        } = await api.inkPublished.bookDetails({ kind, ...bookIdentifier });

        if (ctx.canceled) return;

        const { bookItemNodes, firstDocItemId } = createItemNodes(bookItems);

        setBookDetails({
          book,
          bookItems: bookItemNodes,
          attachFiles,
        });

        if (typeof firstDocItemId === 'number') {
          setBookItemId((prev) => (prev < 0 ? firstDocItemId : prev));
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    },
    [api],
  );

  useEffectApi(
    (ctx) => {
      if (!kind || !bookIdentifier) return;
      doLoadBookDetails(ctx, kind, bookIdentifier);
    },
    [doLoadBookDetails, bookIdentifier],
  );

  const handleClose = () => {
    onClose();
  };

  const { book, bookItems = [], attachFiles = [] } = bookDetails ?? {};

  return (
    <CustomDialog
      className="LectureViewDialog-root"
      open={open}
      maxWidth="lg"
      fullWidth
      onClose={handleClose}
      sx={{
        minWidth: 300,
        ...sxDialogHeight('minHeight', 90),
      }}
    >
      <CustomDialogTitle title="" onClose={handleClose}>
        <Tabs
          value={tabId}
          onChange={(_, tabIndex: number) => {
            setTabId(tabIndex);
          }}
        >
          <Tab label={book?.title ?? '학습 내용'} />
          {attachFiles.length > 0 && <Tab label="학습 자료" />}
        </Tabs>
      </CustomDialogTitle>
      <DialogContent
        dividers
        ref={dialogContentRef}
        sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <Box
          sx={{
            flex: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}
        >
          {tabId === 0 && (
            <Box
              component={Allotment}
              separator={false}
              sx={{
                height: rootSize?.height,
              }}
            >
              <Allotment.Pane minSize={100} preferredSize={280} priority={LayoutPriority.High}>
                <LeftTocList
                  selectedItemId={bookItem?.itemId}
                  bookItems={bookItems}
                  onChangeItem={(itemId) => {
                    setBookItemId(itemId);
                  }}
                  sx={{
                    borderRight: '1px solid #eee',
                    height: rootSize?.height,
                  }}
                />
              </Allotment.Pane>
              <Allotment.Pane minSize={100}>
                {book && bookItem && kind && (
                  <Scrollbars>
                    <LectureContents
                      sx={{
                        flex: 1,
                        pt: 3,
                        pb: 5,
                        pl: 3,
                        pr: 2,
                      }}
                      kind={kind}
                      chapterNum={bookItem.groupSeq}
                      docNum={bookItem.seq}
                      docSn={bookItem.itemId}
                    />
                  </Scrollbars>
                )}
              </Allotment.Pane>
            </Box>
          )}
          {tabId === 1 && <Box>TODO 첨부파일</Box>}
        </Box>

        {loading && (
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
            <LinearProgress color="secondary" />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>취소</Button>
      </DialogActions>
    </CustomDialog>
  );
}

function createItemNodes(bookItems: ink.BookItem[]): {
  bookItemNodes: ItemNode[];
  firstDocItemId: number | undefined;
} {
  const nodes = [] as ItemNode[];
  let lastGroup: ItemNode | undefined;

  // 첫번재 문서의 itemId, 디폴트로 첫번째 문서를 선택하는 용도
  let firstDocItemId: number | undefined;

  for (let i = 0; i < bookItems.length; i++) {
    const item = bookItems[i];
    if (item.itemType === 'chapter') {
      const node: ItemNode = {
        itemId: item.itemId,
        itemType: item.itemType,
        title: item.title,
        groupSeq: nodes.length + 1,
        seq: nodes.length + 1,
        children: [],
      };
      lastGroup = node;
      nodes.push(node);
    } else if (item.itemType === 'doc') {
      if (!firstDocItemId) {
        firstDocItemId = item.itemId;
      }

      if (lastGroup) {
        const node: ItemChild = {
          itemId: item.itemId,
          itemType: item.itemType,
          title: item.title,
          groupSeq: lastGroup.groupSeq,
          seq: lastGroup.children.length + 1,
        };
        lastGroup.children.push(node);
      } else {
        lastGroup = undefined;
        const node: ItemNode = {
          itemId: item.itemId,
          itemType: item.itemType,
          title: item.title,
          groupSeq: undefined,
          seq: nodes.length + 1,
          children: [],
        };
        nodes.push(node);
      }
    }
  }
  return {
    firstDocItemId,
    bookItemNodes: nodes,
  };
}
